import React, { ReactElement } from 'react';

import { GoalReferences, ProgressEntry } from '@bighealth/types';
export { GoalReferences };
// eslint-disable-next-line
import type { Text as TextProps } from '@bighealth/types/dist/scene-components/client';
export { TextProps };


import { matchesSceneSet } from 'components/Routes/matchesRoutes';
import { useHistory } from 'cross-platform/react-router/useHistory';
import { useQueryGoals } from 'lib/api/reactQueryHelpers';
import { ObjectWithAllKeys } from 'lib/utils/generics/ObjectWithAllKeys';
import { OptionalFields } from 'lib/utils/generics/OptionalFields';

import { ImageProps as ImagePropsWithAction } from '../Media/Image';

import { ProgressDetails } from './components/ProgressDetails';
import { getGoalDetailsProps } from './utils/getGoalDetailsProps';

export type ImageProps = OptionalFields<ImagePropsWithAction, 'action'>;
export type GoalLabelTexts = 'Emotional Goal' | 'Daytime Goal' | 'Overall Goal';
export type GoalIcons = 'up' | 'neutral' | 'down';

/**
 * Type mirrored by Scene Component Definition "ProgressGoalsV2"
 */
export type Props = {
  imageMap: ObjectWithAllKeys<GoalReferences, ImageProps>;
  /**
   * Possibly only useful for testing.
   * Would prefer to name this `isContent`, but kept consistent with old Goal component
   */
  isInSceneSet?: boolean;
  comment?: Partial<TextProps>;
  goal?: Partial<TextProps>;
  commentTextMap?: Record<GoalIcons, Record<GoalReferences, string>>;
};
export type ProgressGoalsV2Props = Props;

/**
 * Updated version of "Goals" component
 *
 * @see {@link ./Readme.md} for notes on this component
 * @param {Props['isInSceneSet']} props.isInSceneSet
 * @param {Props['comment']} props.comment
 * @param {Props['goal']} props.goal
 * @param {Props['commentTextMap']} props.commentTextMap
 */
export const ProgressGoalsV2 = (props: Props): ReactElement => {
  const { isInSceneSet } = props;
  const history = useHistory();
  const isContent =
    typeof isInSceneSet === 'boolean'
      ? isInSceneSet // Testing only? override
      : matchesSceneSet(history);
  const goalsResult = useQueryGoals({ isInSceneSet: isContent });
  return (
    <>
      {goalsResult.data?.result.map((progressEntry: ProgressEntry) => {
        const goalDetailsProps = getGoalDetailsProps(props, progressEntry);
        return (
          <ProgressDetails
            key={goalDetailsProps.label.text}
            goal={goalDetailsProps.goal}
            comment={goalDetailsProps.comment}
            image={goalDetailsProps.image}
          />
        );
      })}
    </>
  );
};

ProgressGoalsV2.displayName = 'ProgressGoalsV2';
